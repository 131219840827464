import styled from '@emotion/styled'
import { Golf } from 'app/components/Icons'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

interface Props {
  languageCode: string
  languagePrefix?: string
}

export const ChronoGolf = memo(function ChronoGolf({
  languageCode,
  languagePrefix,
}: Props) {
  return (
    <Container
      href={`https://www.nexxchange.com/search/teetimes/golf-resort-spa-riva-toscana?setlang=${languagePrefix}`}
      rel="noopener"
      target="_blank"
    >
      <Golf />
      {useVocabularyData('book-golf', languageCode)}
    </Container>
  )
})

const Container = styled.a`
  display: flex;
  align-items: center;
  height: 3.75rem;
  background: ${({ theme }) => theme.colors.variants.successLight};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.0437rem;
  line-height: 1.3125rem;
  padding: 0 1.375rem;
  position: absolute;
  right: 0;
  bottom: 50%;
  transform-origin: right bottom;
  transform: rotate(-90deg) translateX(50%);
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  z-index: 5;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.successDark};
  }

  svg {
    width: auto;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin-right: 0.75rem;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`
