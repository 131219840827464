import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  image?: ImageProps
  title?: string
  URL: string
}

export const Item = memo(function Item({ image, title, URL }: Props) {
  if (!URL) {
    return null
  }

  return (
    <Container to={URL}>
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}

      {title ? <Title className="banner-title">{title}</Title> : null}
    </Container>
  )
})

const Container = styled(Link)`
  flex: 1;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  margin-right: 0.125rem;
  padding-bottom: 37.5%;
  position: relative;
  text-align: center;
  &:hover {
    &:before {
      opacity: 0.8;
    }
    img {
      transform: scale(1.05);
    }
    .banner-title {
      &:after {
        right: 0;
        left: 0;
      }
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0)},
      ${rgba(theme.colors.variants.neutralDark1, 1)}
    );
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: 0.3s ease-in-out;
  }

  img {
    width: 101%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.25s ease-in-out;
  }

  @media (max-width: 1023px) {
    display: block;
    margin: 0.125rem 0 0;
    padding-bottom: 57%;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.4375rem;
  position: absolute;
  bottom: 3.75rem;
  left: 50%;
  z-index: 2;
  text-transform: uppercase;
  transform: translateX(-50%);
  &:after {
    content: '';
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    bottom: -0.0625rem;
    right: 50%;
    left: 50%;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1023px) {
    bottom: 2.5rem;
    &:after {
      right: 0;
      left: 0;
    }
  }
`
